import { formatDistanceToNow, format, isThisYear, parseISO } from 'date-fns';

export const formatDate = (isoString) => {
  if (!isoString) return '';
  
  const date = parseISO(isoString);
  const now = new Date();
  const distanceInDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

  if (distanceInDays < 1) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else if (distanceInDays < 7) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else if (isThisYear(date)) {
    return format(date, 'MMMM d');
  } else {
    return format(date, 'MMMM d, yyyy');
  }
}; 