import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingScreen from './screens/LandingScreen';
import PostDetail from './screens/PostDetail';
import CategoryScreen from './screens/CategoryScreen';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route path="/post/:id" element={<PostDetail />} />
          <Route path="/category/:category" element={<CategoryScreen />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
