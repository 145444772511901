import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ChevronLeft } from 'lucide-react';
import { formatDate } from '../utils/dateUtils';
import apiClient from '../api/client';

const CategoryScreen = () => {
  const { category } = useParams();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await apiClient.get(`/api/posts/category/${category}`);
        setPosts(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [category]);

  if (loading) {
    return <div className="min-h-screen bg-black flex items-center justify-center text-white">Loading...</div>;
  }

  if (error) {
    return <div className="min-h-screen bg-black flex items-center justify-center text-red-500">{error}</div>;
  }

  const categoryTitles = {
    'news': 'Company News',
    'case-study': 'Case Studies',
    'research': 'Research',
    'clinical': 'Clinical'
  };

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      <div className="max-w-7xl mx-auto px-8 py-24">
        <div className="mb-16">
          <Link 
            to="/" 
            className="inline-flex items-center text-white/40 hover:text-white transition-colors mb-8 group"
          >
            <ChevronLeft className="w-5 h-5 mr-2 group-hover:-translate-x-1 transition-transform" />
            Back to Home
          </Link>
          <h1 className="text-5xl font-bold tracking-tight bg-gradient-to-r from-white to-white/60 bg-clip-text text-transparent">
            {categoryTitles[category] || category}
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-12">
          {posts.map((post) => (
            <Link
              key={post.id}
              to={`/post/${post.id}`}
              className="group block"
            >
              <div className="relative overflow-hidden rounded-2xl mb-4 h-[280px]">
                <img
                  src={post.image}
                  alt=""
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <div className="absolute bottom-0 left-0 right-0 p-8">
                  <div className="space-y-4">
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-white/80 font-medium px-3 py-1 bg-white/10 rounded-full">
                        {post.type}
                      </span>
                      <span className="text-white/60">{formatDate(post.timestamp)}</span>
                    </div>
                    <h3 className="text-2xl font-medium text-white group-hover:text-white/90 transition-colors">
                      {post.title}
                    </h3>
                    <p className="text-white/80 text-base line-clamp-2 leading-relaxed">
                      {post.preview}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryScreen; 