import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ChevronLeft } from 'lucide-react';
import { formatDate } from '../utils/dateUtils';
import apiClient from '../api/client';

const PostDetail = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await apiClient.get(`/api/posts/${id}`);
        setPost(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <div className="min-h-screen bg-black flex items-center justify-center text-white">Loading...</div>;
  }

  if (error || !post) {
    return <div className="min-h-screen bg-black flex items-center justify-center text-red-500">{error || 'Post not found'}</div>;
  }

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      <div className="h-[60vh] md:h-[70vh] relative">
        <img
          src={post.image}
          alt=""
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-black/20 to-transparent" />
        <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent" />
        
        <div className="absolute top-0 left-0 right-0">
          <div className="max-w-7xl mx-auto px-4 md:px-8 py-6 md:py-12">
            <Link 
              to="/" 
              className="inline-flex items-center text-white/80 hover:text-white transition-colors group"
            >
              <ChevronLeft className="w-5 h-5 mr-2 group-hover:-translate-x-1 transition-transform" />
              Back
            </Link>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">
          <div className="max-w-7xl mx-auto px-4 md:px-8 pb-8 md:pb-16">
            <div className="max-w-3xl space-y-3 md:space-y-4">
              <div className="flex items-center gap-3 md:gap-4 text-sm">
                <span className="text-white/80 font-medium px-3 py-1 bg-white/10 rounded-full">
                  {post.type}
                </span>
                <span className="text-white/60">{formatDate(post.timestamp)}</span>
              </div>
              <h1 className="text-3xl md:text-5xl font-bold tracking-tight text-white">
                {post.title}
              </h1>
              <p className="text-lg md:text-xl text-white/80 leading-relaxed line-clamp-3 md:line-clamp-none">
                {post.preview}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 md:px-8 py-8 md:py-16">
        <div className="max-w-3xl mx-auto">
          <ReactMarkdown
            className="prose prose-invert prose-base md:prose-lg max-w-none"
            remarkPlugins={[remarkGfm]}
            components={{
              code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={oneDark}
                    language={match[1]}
                    PreTag="div"
                    className="rounded-xl my-6 text-sm md:text-base"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              },
              p: ({children}) => (
                <p className="text-white/80 leading-relaxed mb-4 md:mb-6 text-base md:text-lg">
                  {children}
                </p>
              ),
              h2: ({children}) => (
                <h2 className="text-2xl md:text-3xl font-bold tracking-tight mt-8 md:mt-12 mb-4 md:mb-6">
                  {children}
                </h2>
              ),
              h3: ({children}) => (
                <h3 className="text-xl md:text-2xl font-semibold tracking-tight mt-6 md:mt-8 mb-3 md:mb-4">
                  {children}
                </h3>
              ),
              ul: ({children}) => (
                <ul className="list-disc list-inside space-y-2 mb-4 md:mb-6 text-white/80">
                  {children}
                </ul>
              ),
              ol: ({children}) => (
                <ol className="list-decimal list-inside space-y-2 mb-4 md:mb-6 text-white/80">
                  {children}
                </ol>
              ),
              li: ({children}) => (
                <li className="leading-relaxed text-base md:text-lg">
                  {children}
                </li>
              ),
              blockquote: ({children}) => (
                <blockquote className="border-l-4 border-white/20 pl-4 italic text-white/60 my-4 md:my-6 text-base md:text-lg">
                  {children}
                </blockquote>
              ),
              a: ({href, children}) => (
                <a 
                  href={href} 
                  className="text-blue-400 hover:text-blue-300 transition-colors break-words"
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {children}
                </a>
              ),
              img: ({src, alt}) => (
                <img 
                  src={src} 
                  alt={alt} 
                  className="rounded-xl w-full my-4 md:my-6"
                />
              ),
            }}
          >
            {post.content}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default PostDetail; 