import axios from 'axios';

const getApiBaseUrl = () => {
    if (process.env.NODE_ENV === 'production') {
        // Get the current domain (either archii.io or archii.ai)
        const currentDomain = 'archiibackend-lbrendle.replit.app';
        return `https://${currentDomain}`;
    }
    return 'http://localhost:8000'; // Development URL
};

const apiClient = axios.create({
  baseURL: getApiBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const getPosts = () => apiClient.get('/api/posts');
export const getPost = (id) => apiClient.get(`/api/posts/${id}`);

export default apiClient; 