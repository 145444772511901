import React, { useState, useEffect } from 'react';
import { Send, X, ChevronDown, ChevronRight, Menu } from 'lucide-react';
import { Link } from 'react-router-dom';
import { formatDate } from '../utils/dateUtils';
import apiClient from '../api/client';

const NewsFeed = () => {
  const [showChat, setShowChat] = useState(false);
  const [message, setMessage] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [showLoginDropdown, setShowLoginDropdown] = useState(false);
  const [feedItems, setFeedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeSection, setActiveSection] = useState('all');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState('');

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await apiClient.get('/api/posts');
        // Sort posts by timestamp in descending order (most recent first)
        const sortedData = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        setFeedItems(sortedData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch posts');
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handleSendMessage = () => {
    if (message.trim()) {
      setChatMessages([...chatMessages, { text: message, sender: 'user' }]);
      setTimeout(() => {
        setChatMessages(prev => [...prev, {
          text: "I understand what you're going through. Let's explore this together.",
          sender: 'ai'
        }]);
      }, 1000);
      setMessage('');
    }
  };

  if (loading) {
    return <div className="min-h-screen bg-black flex items-center justify-center text-white">Loading...</div>;
  }

  if (error) {
    return <div className="min-h-screen bg-black flex items-center justify-center text-red-500">{error}</div>;
  }

  // Sort sections in desired order
  const sectionOrder = ['news', 'case-study', 'research', 'clinical'];
  
  // Group and sort posts by type
  const groupedPosts = feedItems.reduce((acc, post) => {
    if (!acc[post.type]) {
      acc[post.type] = [];
    }
    acc[post.type].push(post);
    return acc;
  }, {});

  // Sort the entries based on the desired order
  const sortedEntries = Object.entries(groupedPosts).sort(([typeA], [typeB]) => {
    return sectionOrder.indexOf(typeA.toLowerCase()) - sectionOrder.indexOf(typeB.toLowerCase());
  });

  const PostCard = ({ post, size = 'normal' }) => (
    <Link
      to={`/post/${post.id}`}
      className={`group block ${size === 'large' ? 'w-full' : 'min-w-[400px] max-w-[400px]'}`}
    >
      <div className={`relative overflow-hidden rounded-2xl mb-4 ${size === 'large' ? 'h-[70vh]' : 'h-[280px]'}`}>
        <img
          src={post.image}
          alt=""
          className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute bottom-0 left-0 right-0 p-8">
          <div className="space-y-4">
            <div className="flex items-center justify-between text-sm">
              <span className="text-white/80 font-medium px-3 py-1 bg-white/10 rounded-full">
                {post.type}
              </span>
              <span className="text-white/60">{formatDate(post.timestamp)}</span>
            </div>
            <h3 className={`font-medium text-white group-hover:text-white/90 transition-colors ${
              size === 'large' ? 'text-3xl' : 'text-2xl'
            }`}>
              {post.title}
            </h3>
            <p className="text-white/80 text-base line-clamp-2 leading-relaxed">{post.preview}</p>
          </div>
        </div>
      </div>
    </Link>
  );

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      {/* Mobile Menu Button */}
      <button 
        onClick={() => setIsMobileMenuOpen(true)}
        className="fixed md:hidden left-4 top-4 z-50 p-2 hover:bg-white/10 rounded-xl transition-colors"
      >
        <div className="space-y-2">
          <div className="w-6 h-px bg-white/60 transform transition-all duration-300"></div>
          <div className="w-4 h-px bg-white/60 transform transition-all duration-300"></div>
        </div>
      </button>

      {/* Mobile Menu Overlay */}
      {isMobileMenuOpen && (
        <div 
          className="fixed inset-0 bg-black/80 backdrop-blur-sm z-40 md:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      {/* Side Navigation - Updated with responsive classes */}
      <nav className={`fixed left-0 top-0 h-full bg-black border-r border-white/10 flex flex-col items-center py-8 transition-all duration-300 z-50
        ${isMobileMenuOpen ? 'w-64 translate-x-0' : 'w-20 -translate-x-full md:translate-x-0'}`}
      >
        {/* Close button for mobile */}
        <button 
          onClick={() => setIsMobileMenuOpen(false)}
          className="absolute right-4 top-4 p-2 hover:bg-white/10 rounded-lg transition-colors md:hidden"
        >
          <X className="w-5 h-5" />
        </button>

        {/* Main Navigation */}
        <div className="flex flex-col items-center space-y-6 mt-12 md:mt-0">
          <button 
            onMouseEnter={() => setShowTooltip('em')}
            onMouseLeave={() => setShowTooltip('')}
            className="relative text-sm text-white/40 hover:text-white transition-colors"
          >
            em
            {showTooltip === 'em' && (
              <span className="absolute left-full ml-2 px-2 py-1 bg-white/10 rounded text-white whitespace-nowrap">
                Coming soon
              </span>
            )}
          </button>
          <button 
            onMouseEnter={() => setShowTooltip('tod')}
            onMouseLeave={() => setShowTooltip('')}
            className="relative text-sm text-white/40 hover:text-white transition-colors"
          >
            tod
            {showTooltip === 'tod' && (
              <span className="absolute left-full ml-2 px-2 py-1 bg-white/10 rounded text-white whitespace-nowrap">
                Coming soon
              </span>
            )}
          </button>
          <button 
            onMouseEnter={() => setShowTooltip('me')}
            onMouseLeave={() => setShowTooltip('')}
            className="relative text-sm text-white/40 hover:text-white transition-colors"
          >
            me
            {showTooltip === 'me' && (
              <span className="absolute left-full ml-2 px-2 py-1 bg-white/10 rounded text-white whitespace-nowrap">
                Coming soon
              </span>
            )}
          </button>
        </div>

        {/* Login Section */}
        <div className="mt-auto">
          <div className="relative group">
            <button 
              onMouseEnter={() => setShowTooltip('login')}
              onMouseLeave={() => setShowTooltip('')}
              className="flex items-center gap-2 px-4 py-2 text-sm text-white/40 hover:text-white transition-colors rounded-lg hover:bg-white/5"
            >
              <span>Login</span>
              <ChevronRight className="w-4 h-4" />
              {showTooltip === 'login' && (
                <span className="absolute left-full ml-2 px-2 py-1 bg-white/10 rounded text-white whitespace-nowrap">
                  Coming soon
                </span>
              )}
            </button>
          </div>
        </div>
      </nav>

      {/* Updated Chat Component */}
      <div className={`fixed top-0 md:left-20 left-0 right-0 z-40 transition-all duration-500 ${showChat ? 'h-96' : 'h-16'}`}>
        <div className="max-w-7xl mx-auto px-8">
          <div className="relative mt-4">
            {!showChat ? (
              <button
                onMouseEnter={() => setShowTooltip('chat')}
                onMouseLeave={() => setShowTooltip('')}
                className="relative w-full px-8 py-4 bg-white/5 rounded-2xl text-white/40 text-left hover:bg-white/10 transition-all duration-300 border border-white/5 hover:border-white/10"
              >
                Start a conversation...
                {showTooltip === 'chat' && (
                  <span className="absolute left-8 top-full mt-2 px-2 py-1 bg-white/10 rounded text-white whitespace-nowrap">
                    Coming soon
                  </span>
                )}
              </button>
            ) : (
              <div className="bg-white/5 rounded-2xl p-6 border border-white/10">
                <div className="flex justify-between items-center mb-6">
                  {/* <span className="text-lg font-medium">archii ai</span> */}
                  <button 
                    onClick={() => setShowChat(false)}
                    className="p-2 hover:bg-white/10 rounded-lg transition-colors"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>
                
                <div className="h-64 overflow-y-auto mb-6 space-y-4">
                  {chatMessages.map((msg, index) => (
                    <div key={index} className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                      <div className={`max-w-xs p-4 rounded-2xl ${
                        msg.sender === 'user' 
                          ? 'bg-blue-600 text-white' 
                          : 'bg-white/10 text-white/90'
                      }`}>
                        {msg.text}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="flex space-x-3">
                  <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message..."
                    className="flex-1 px-6 py-4 bg-white/10 rounded-xl focus:outline-none focus:ring-1 focus:ring-white/20 border border-white/5"
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                  />
                  <button
                    onClick={handleSendMessage}
                    className="p-4 rounded-xl bg-blue-600 hover:bg-blue-700 transition-colors"
                  >
                    <Send className="w-5 h-5" />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Updated Main Content */}
      <div className="md:pl-20 pl-0 pb-16">
        <div className="max-w-7xl mx-auto px-8 pt-24">
          <div className="max-w-3xl">
            <h1 className="text-5xl font-bold mb-6 tracking-tight bg-gradient-to-r from-white to-white/60 bg-clip-text text-transparent">
              archii
            </h1>
            <p className="text-white/60 text-xl leading-relaxed mb-20">
              Pioneering advancements in mental health and psychology through AI and human experts.
            </p>
          </div>

          {/* Latest Post */}
          {feedItems.length > 0 && (
            <div className="mb-32">
              <PostCard post={feedItems[0]} size="large" />
            </div>
          )}

          {/* Category Sections */}
          {sortedEntries.map(([type, posts]) => (
            <div key={type} className="mb-32">
              <div className="flex items-center justify-between mb-12">
                <h2 className="text-2xl font-semibold tracking-tight">{type}</h2>
                <Link 
                  to={`/category/${type.toLowerCase()}`} 
                  className="text-sm text-white/40 hover:text-white flex items-center gap-2 group"
                >
                  View all 
                  <ChevronRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                </Link>
              </div>
              <div className="relative -mx-8">
                <div className="relative px-8">
                  <div className="flex space-x-8 overflow-x-auto pb-8 scrollbar-hide">
                    {posts.map((post) => (
                      <PostCard key={post.id} post={post} />
                    ))}
                  </div>
                  <div className="absolute left-0 top-0 bottom-8 w-8 bg-gradient-to-r from-black to-transparent pointer-events-none" />
                  <div className="absolute right-0 top-0 bottom-8 w-8 bg-gradient-to-l from-black to-transparent pointer-events-none" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewsFeed;